import Vue from 'vue'

export default {
    fetchNotifications: function (params) {
        return Vue.$http.get('notifications', {params});
    },
    setAsRead: function (payload) {
        return Vue.$http.post('/notifications/set-as-read', payload);
    }
}
