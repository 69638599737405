<template>
  <section class="d-block filters d-sm-flex">
    <v-sheet max-width="250px">

    </v-sheet>
    <v-sheet max-width="250px">
      <v-select dense outlined  :items="statuses" item-text="label" item-value="value" v-model="filters.status" label="Status" @input="filter()"/>
    </v-sheet>
    <v-sheet  max-width="250px">
      <select-assignee-dropdown :dense="true" @input="filter()" label="Caretaker" v-model="filters.assignee_id"/>
    </v-sheet>
    <v-sheet max-width="250px">

    </v-sheet>



    <v-btn class="ml-auto hidden-xs-only" @click="resetFilters">Clear</v-btn>
  </section>
</template>

<script>
import LebenDatePicker from "@/views/components/LebenDatePicker";
import {EventBus} from "@/bootstrap/EventBus";
import SelectAssigneeDropdown from "@/components/forms/SelectAssigneeDropdown.vue";
import SelectCustomerDropdown from "@/components/forms/SelectCustomerDropdown.vue";

export default {
  name: "RotationsContractTableFilters",
  props :  ["defaults","contract_id"],
  components: {
    SelectCustomerDropdown,
    SelectAssigneeDropdown,
    LebenDatePicker
  },
  data: function () {
    return {
      filters: {
        state: null,
        status: null,
        customer_id: null,
        assignee_id: null,
      },
      statuses: [
        {
          label: 'draft',
          value: 'draft'
        },
        {
          label: 'gap',
          value: 'gap'
        },
        {
          label: 'closed',
          value: 'closed'
        },
        {
          label: 'active',
          value: 'active'
        },
        {
          label: 'complete',
          value: 'complete'
        },
      ],


      states: [
        {
          label: 'Finished',
          value: 'finished'
        },
        {
          label: 'Started',
          value: 'started'
        },
      ]
    }
  },
  created() {

  },
  mounted: function () {
    this.filters = Object.assign({},this.defaults);
    this.filter()
  },
  methods: {
    filter: function () {
      EventBus.$emit('rotations-update', this.filters);
    },
    resetFilters: function () {
      this.filters = {
      };
      this.filter();
    },
  },
}
</script>
