// @ts-ignore
import i18n from "@/bootstrap/i18n";

const state = {

    customers: [],
};


const getters = {
    SelectedCustomers(state: any) {
        return state.customers;
    },


};






const actions = {
    /** On Success Login **/
    selectCustomer(context, data) {
        console.log('select ');
        context.commit('addCustomer', data);
    },

    addCustomer({commit}, payload) {
        i18n.locale = payload;
        commit('setLocale', payload);
    },

    purgeCustomerSelection({commit}, payload): void {
        commit('purgeCustomers', );
    },

};

// @ts-ignore
const mutations = {

    purgeCustomers: function (state: any, payload: any) {

        console.log('purge')
  state.customers = [];

    },

    addCustomer: function (state: any, payload: any) {

        const existingCustomerIndex = state.customers.findIndex((customer) => customer.id === payload.id);


        if (existingCustomerIndex === -1) {
            // If the customer doesn't exist, add it to the array
            state.customers.push(payload);
        }else  {
            console.log('exists');
        }


    },
};

export const customers = {
    state,
    getters,
    mutations,
    actions
};
