<template>
    <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="500"
  >
  <v-sheet class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
        <div>
          <h3 class="mb-5">Cancellation reason</h3>
        </div>
      </div>

      <div>
        <v-textarea
            v-model="reason"
            label="Enter the reason for cancelling the task"
            placeholder="Cancellation reason"
            outlined
        ></v-textarea>
        <div class="text-caption red--text mt-n7" v-show="$v.reason.$dirty && $v.reason.$invalid && !$v.reason.required">Field is required</div>
        <div class="text-caption red--text mt-n7" v-show="$v.reason.$dirty && $v.reason.$invalid && !$v.reason.maxLength">Maximum reason length is 400</div>
      </div>

      <div class="d-flex">
        <v-btn class="mt-5 mx-auto" @click="showDialog = false">Cancel</v-btn>
        <v-btn class="mt-5 mx-auto" color="primary" @click="submit">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/bootstrap/EventBus';
import { required, maxLength  } from 'vuelidate/lib/validators'

export default {
    name: 'TaskCancelPopup',
    data: function() {
        return {
            showDialog: false,
            reason: null,
        } 
    },
    validations: {
        reason: {
            required,
            maxLength: maxLength(400)
        }
    },
    created() {
        EventBus.$on('task-cancel-popup', () => {
          this.showDialog = true
        })
    },
    methods: {
        submit() {
            this.$v.$touch()
            if(this.$v.$invalid) return;
            EventBus.$emit('cancel-task-with-reason', {reason: this.reason})
            this.reason = ''
            this.showDialog = false
        }
    }
}
</script>