<template>
    <!-- dark -->
    <v-col cols="6">
        <v-card>
            <v-card-title>Welcome, {{$store.getters['auth/user'].name}} ({{role}})</v-card-title>
            <v-card-subtitle>
                This is the {{$route.name}} Page
            </v-card-subtitle>
          <v-img
              v-if="$store.getters['auth/user'].picture"
              :src="$store.getters['auth/user'].picture"
              max-height="280"
              max-width="280"
          ></v-img>
          <!-- Otherwise, display a default image or an empty space -->
          <v-img
              v-else
              max-height="280"
              max-width="280"
              src="https://lowcars.net/wp-content/uploads/2017/02/userpic.png"
          ></v-img>
        </v-card>
    </v-col>
</template>


<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'BlankPageContent',

      created() {
          console.log(this.$store.getters['auth/user'])
      },
      computed: {
        ...mapGetters('auth', ['role']),
      },
    }

</script>
