<template>
  <v-app>
    <!-- Top Bar -->
    <default-top-bar/>

    <!-- Sidebar + Main Navigation -->
    <default-side-bar/>

    <!-- Main Content -->
    <v-main>
      <div class="app-content-container boxed-container pa-sm-6">
        <slot></slot>
      </div>
    </v-main>

    <validation-error-block/>

    <!--Footer-->
    <v-footer app inset color="transparent" absolute height="32" class="px-0 hidden-xs-only">
      <p class="ml-auto">
        LebenSwerte 2021.
      </p>
    </v-footer>
    <tasks-create-popup/>
    <tasks-q-s-visit-popup/>
    <tasks-view-popup/>
    <task-cancel-popup />
  </v-app>
</template>

<script>
import {mdiMagnify, mdiBellOutline, mdiGithub} from '@mdi/js'

import DefaultTopBar from "@/views/layout/default/components/topbar/DefaultTopBar";
import DefaultSideBar from "@/views/layout/default/components/sidebar/DefaultSideBar";
import ValidationErrorBlock from "@/views/layout/components/validation/ValidationErrorBlock";
import TasksCreatePopup from "@/views/pages/tasks/create/TasksCreatePopup";
import TasksQSVisitPopup from "@/views/pages/tasks/create/TasksQSVisitPopup";
import TasksViewPopup from "@/views/pages/tasks/show/TasksViewPopup";
import TaskCancelPopup from '@/views/pages/tasks/cancel/TaskCancelPopup';

export default {
  name: 'DefaultContentLayout',
  components: {
    TasksViewPopup,
    TasksQSVisitPopup, TasksCreatePopup, ValidationErrorBlock, DefaultSideBar, DefaultTopBar, TaskCancelPopup},
  data: () => ({
    icons: {
      mdiMagnify,
      mdiBellOutline,
      mdiGithub,
    },
  }),
}
</script>

<style>
.v-data-table__mobile-row__header {
  width: 100% !important;
  display: none !important;
}

.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}

.v-data-table-header-mobile {
  display: none;
}

h1 {
  font-size: 18px;
  font-weight: 900;
}

.v-data-table__mobile-row {
  padding: 0 !important;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: auto !important;
}

</style>
